import React from 'react'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const Header = () => {
  return (
    <Container disableGutters sx={{maxWidth:'768px', width : 1, pt : '140px'}}>
      <Typography component="h2" id="alliance"
        sx={{
            fontSize: '64px',
            fontWeight: 600,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#fff',
            mb: '60px',
        }}
      >
        Hancom Alliance
      </Typography>
      <Typography
        sx={{
          opacity: 0.7,
          fontSize: '22px',
          fontWeight: 500,
          lineHeight: '1.36',
          letterSpacing: 'normal',
          color: '#fff',
          maxWidth: '650px',
        }}
      >
        You Serve Your Customers, and We Serve You!<br /><br />

        You design the full set of solution for your customers' problems.<br />
        And our goal is to help you to build those fast.<br />
        Hancom provides an extensive set of SDKs/APIs, and keeps investing to extend our offerings.
      </Typography>
      <Typography className='AcquisitionTypo' sx={{ mt: 5 }}>
        You can join Hancom Alliance in two ways.
      </Typography>
      <ListItem className='AcquisitionListItem'>
        <ListItemIcon>
          <FiberManualRecordIcon />
        </ListItemIcon>
        <ListItemText primary="We co-promote your finished products equipped with Hancom technology." />
      </ListItem>
      <ListItem className='AcquisitionListItem'>
        <ListItemIcon>
          <FiberManualRecordIcon />
        </ListItemIcon>
        <ListItemText primary="We co-promote a combined technology set together." />
      </ListItem>
      <Typography className='AcquisitionTypo' sx={{ mt: 5 }}>
      Also, please note that we always welcome new family companies. See the Acquisition section below for details.
      </Typography>
    </Container>
  )
}

export default Header