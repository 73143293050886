import React from 'react'
import './Acquisition.css';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ListAltIcon from '@mui/icons-material/ListAlt';


const Acquisition = () => {
  return (
    <Container disableGutters sx={{maxWidth:'768px',  mt : '120px'}}>
      <Typography component="h2" id="acquisition"
        sx={{
            fontSize: '64px',
            fontWeight: 600,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#fff',
            mb: '40px',
        }}
      >
        Acquisition
      </Typography>
      <Typography className='AcquisitionTypo'>
        Hancom has a long history with a solid cash flow.<br/>
        We invite founders or capable management teams who want to collaborate with us to expand markets and products.<br/>
        Hancom would draw your new start together as a family under the brand of Hancom Alliance.
        </Typography>
        <Typography className='AcquisitionTypo' sx={{mt:5}}>
            We welcome new families
        </Typography>
        <ListItem className='AcquisitionListItem'>
            <ListItemIcon>
                <FiberManualRecordIcon />
            </ListItemIcon>
            <ListItemText primary="We welcome new families"/>
        </ListItem>
        <ListItem className='AcquisitionListItem'>
            <ListItemIcon>
                <FiberManualRecordIcon />
            </ListItemIcon>
            <ListItemText primary="with an eagerness to solve a specific problem of target customers"/>
        </ListItem>
        <ListItem className='AcquisitionListItem'>
            <ListItemIcon>
                <FiberManualRecordIcon />
            </ListItemIcon>
            <ListItemText primary="with positive energy"/>
        </ListItem>
        <ListItem className='AcquisitionListItem'>
            <ListItemIcon>
                <FiberManualRecordIcon />
            </ListItemIcon>
            <ListItemText primary="from any region"/>
        </ListItem>
        <ListItem className='AcquisitionListItem'>
            <ListItemIcon>
                <FiberManualRecordIcon />
            </ListItemIcon>
            <ListItemText primary="with a history of achieving a financial break-even"/>
        </ListItem>
        <Button 
            variant="contained" 
              target="_blank"
              href='https://hform.hancomdocs.com/form/invite/MTY3NTcyNDkxNDM3NA_772b9772b9fu2b2fve2b2fve2b2fvx4xnjc1nzizotk3mdyy'
            endIcon={<ListAltIcon/>} 
            sx={{
              backgroundColor : 'rgba(255,255,255)', 
              borderRadius : '20px', 
              height : '40px', 
              textTransform : 'none',
              fontSize : '16px',
              color:'#141414',
              fontWeight : 600,
              mt : 5,
              }}>Contact Us
          </Button>
    </Container>
  )
}

export default Acquisition