import React from 'react'
import './Sdk.css';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import OfficeEditorSDK from 'assets/images/OfficeEditorSDK@2x.png';
import AiChatbotSDK from 'assets/images/AiChatbotSDK@2x.png';
import AiOcrSDK from 'assets/images/AiOcrSDK@2x.png';
import CalcSDK from 'assets/images/CalcSDK@2x.png';
import OfficeViewerSDK from 'assets/images/OfficeViewerSDK@2x.png';
import SpellcheckSDK from 'assets/images/SpellcheckSDK@2x.png';
import HANCOMSDK from 'assets/pdf/HANCOM_SDK.pdf';

import HNCM_OfficeSDK from 'assets/video/HNCM_OfficeSDK_(ENG_FHD).mp4'
import HNCM_OCRSDK from 'assets/video/HNCM_OCRSDK_(ENG_FHD).mp4'
import HNCM_ChatbotSDK from 'assets/video/HNCM_ChatbotSDK_(ENG_FHD).mp4'
import HNCM_CalcSDK from 'assets/video/HNCM_CalcSDK_(ENG_FHD).mp4'
// import { Modal } from '@mui/material';

// import { ThemeProvider, createTheme } from '@mui/material/styles';

// const theme = createTheme({
//   components: {
//     MuiCssBaseline: {
//       styleOverrides: `
//       button {
//         backgroundColor : 'red !important', 
//       `,
//     },
//   },
// });

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width : '100%',
  maxWidth: '960px',
  // maxHeight: '90vh',
  // p:2,
};


function DocIcon() {
  return (
    <SvgIcon sx={{width : '24px', height: '24px'}} viewBox="0 0 24 24">
     <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2h12c1.1 0 2 .9 2 2v16c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2zm0 18h12V4H6v16zM16 6H8v2h8V6zm-8 4h8v2H8v-2zm5 4H8v2h5v-2z" fill="#fff"/>
    </SvgIcon>
  );
}

function Cards({ cards }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Grid item xs={12} sm={6}>
      <Card className='sdk_card'>
        <CardContent sx={{p : 0}}>
          <Typography variant="h5" component="h3" className='card_tit' sx={{color : '#202020'}}>
            {cards.title}
          </Typography>
          <Typography
            sx={{
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: '1.38',
              letterSpacing: 'normal',
              color: '#202020',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '6',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {cards.contents}
          </Typography>
        </CardContent>
        <CardActions sx={{p : 0, mt : 3}}>
          <Button 
            variant="contained" 
            endIcon={<OndemandVideoIcon/>} 
            className="watchMore"
            // href={cards.video}
            // target='_blank'
            onClick={handleOpen}
            sx={{
              backgroundColor : 'rgba(0, 0, 0, 0.7)', 
              borderRadius : '20px', 
              height : '40px', 
              textTransform : 'none',
              fontSize : '16px',
              minWidth : '155px'
              }}>Watch more
          </Button>
          <IconButton variant="contained" className="iconButton pdfView" href={cards.pdf} target='_blank'>
            <DocIcon/>
          </IconButton>
          <IconButton variant="contained" className="iconButton" href={cards.email}>
            <MailOutlineIcon/>
          </IconButton>
        </CardActions>

        <CardMedia
          component="img" className='card_img'
          sx={{
           backgroundImage: 'url(' + cards.img + ')',
          }}
          // image={cards.img}
          // alt={cards.imgAlt}
        />
      </Card>
      
      
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
          <video controls width="100%" autoPlay sx="objectFit:fill">
            <source src={cards.video} type="video/mp4"/>
          </video>
          </Box>
        </Modal>
    </Grid>
  );
}

const cards = [
  {
    id: 1,
    title: 'Office Editor SDK',
    contents: 'The Editor SDK is a tool built using the core technology of Hancom Office that allows you to add the editor to any location and customize the Office to fit local needs.',
    video : HNCM_OfficeSDK,
    pdf: HANCOMSDK,
    img : OfficeEditorSDK,
    imgAlt : 'img01',
    email: 'mailto:contact_sdk@hancom.com'
  },
  {
    id: 2,
    title: 'Office Viewer SDK',
    contents: 'The Viewer SDK is a tool that allows you to include a viewer in your application and view documents, enabling document viewing from mobile devices and increasing work efficiency.',
    pdf: HANCOMSDK,
    img : OfficeViewerSDK,
    imgAlt : 'img02',
    email: 'mailto:contact_sdk@hancom.com'
  },
  {
    id: 3,
    title: 'AI OCR SDK',
    contents: 'This SDK with our deep learning technology provides optimized OCR performance in different environments beyond simple text detection and recognition.',
    video : HNCM_OCRSDK,
    pdf: HANCOMSDK,
    img : AiOcrSDK,
    imgAlt : 'img03',
    email: 'mailto:contact_sdk@hancom.com'
  },
  {
    id: 4,
    title: 'AI Chatbot SDK',
    contents: 'Optimized for work environments, Chatbot SDK works with the web-based document system and provides function inquiry, knowledge search, and daily conversation.',
    video : HNCM_ChatbotSDK,
    pdf: HANCOMSDK,
    img : AiChatbotSDK,
    imgAlt : 'img04',
    email: 'mailto:contact_sdk@hancom.com'
  },
  {
    id: 5,
    title: 'Calc SDK',
    contents: 'This reliable SDK is verified by years of testing and enables you to calculate anywhere you want to manage a large amount of data, such as ERP and CRM.',
    video : HNCM_CalcSDK,
    pdf: HANCOMSDK,
    img : CalcSDK,
    imgAlt : 'img05',
    email: 'mailto:contact_sdk@hancom.com'
  },
  {
    id: 6,
    title: 'Spellcheck SDK',
    contents: 'Spellcheck SDK optimized for Korean is applicable to wherever you need a Korean service and enhances the quality of your service.Coming soon!',
    pdf: HANCOMSDK,
    img : SpellcheckSDK,
    imgAlt : 'img06',
    email: 'mailto:contact_sdk@hancom.com'
  }
];

function Sdk() {
 
  return (
    <Container disableGutters sx={{maxWidth:'768px', width : 1}}>
      <Typography component="h2" className='sdk_tit' id='sdk'># Hancom SDK</Typography>
      <Grid container spacing={2} sx={{mt:0}} className='skdCardWrap'>
          {cards.map(cards => (
            <Cards cards={cards} key={cards.id} />
          ))}
        </Grid>
        
    </Container>
  )
}

export default Sdk