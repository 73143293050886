import React from 'react'
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import IconButton from '@mui/material/IconButton';

import MarkupIcon from 'assets/images/MarkupIcon.png';
import NoteLedgeIcon from 'assets/images/NoteLedgeIcon.png';
import AnimationIcon from 'assets/images/AnimationIcon.png';
import VideoIcon from 'assets/images/VideoIcon.png';
import PDFSDK from 'assets/images/PDFSDK@2x.png';

function Cards({ cards }) {
  return (
    <Grid item xs={12} sm={6} >
      <Card className='service_card' sx={{ backgroundColor : cards.backgroundColor, pb : '0px'}}>
        <CardContent sx={{p : 0}}>
          <img src={cards.img} alt={cards.alt} width='88px'/>
          <Typography variant="h5" component="h3" className='card_tit' sx={{color : '#141414', mt : 4}}>
            {cards.title}
          </Typography>
          <Typography
            sx={{
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: '1.38',
              letterSpacing: 'normal',
              color: '#141414',
              whiteSpace : 'pre-wrap'
            }}
          >
            {cards.contents}
          </Typography>
        </CardContent>
        <CardActions sx={{p : 0, mt : 'auto'}}>
          <Button 
            variant="contained"
            href={cards.url}
            target="_blank"
            endIcon={<ArrowForwardIcon/>} 
            sx={{
              backgroundColor : 'rgba(0, 0, 0, 0.7)', 
              borderRadius : '20px', 
              height : '40px', 
              textTransform : 'none',
              fontSize : '16px'
              }}>Go to Product
          </Button>
          <IconButton variant="contained" className="iconButton" href={cards.email}>
            <MailOutlineIcon/>
          </IconButton>
        </CardActions>
      </Card>
    </Grid>
  );
}

const cards = [
  {
    id: 1,
    img : MarkupIcon,
    alt : 'MarkupIcon',
    title: 'Markup',
    contents: 'Markup helps you remember what you read with powerful tools, including text highlighter, AI summarizer, and annotation notes.',
    color: '#fff',
    url: 'https://markups.kdanmobile.com',
    email: 'mailto:business@kdanmobile.com'
  },
  {
    id: 2,
    img : NoteLedgeIcon,
    alt : 'NoteLedgeIcon',
    title: 'NoteLedge',
    contents: 'NoteLedge comes with powerful multimedia tools for you to capture inspiration anywhere. Discover and save ideas from Pinterest and websites. Take notes and add annotations to highlight what matters most.',
    color: '#fff',
    url: 'https://www.kdanmobile.com/en/noteledge',
    email: 'mailto:business@kdanmobile.com'
  },
  {
    id: 3,
    img : AnimationIcon,
    alt : 'AnimationIcon',
    title: 'Animation Desk',
    contents: 
    `The Best 2D Animation Software.\nThe simple and beautiful software for creating animation, cartoons, and animatics.`,
    color: '#202020',
    url: 'https://www.kdanmobile.com/en/animation-desk',
    email: 'mailto:business@kdanmobile.com'
  },
  {
    id: 4,
    img : VideoIcon,
    alt : 'VideoIcon',
    title: 'Write-on Video',
    contents: 'The Video Editor for Storytellers. Create Storyboards, Edit Video, and Make an Impact.',
    color : '#fff',
    url: 'https://www.kdanmobile.com/en/writeon-video',
    email: 'mailto:business@kdanmobile.com'
  }
];

function Kdan() {
  return (
    <Container disableGutters sx={{maxWidth:'768px', width : 1}}>
      <Typography component="h2" id="kdan" className='sdk_tit'># Kdan Mobile</Typography>
        <Grid container spacing={2} sx={{ mt: 0 }}>
          <Grid item xs={12} sm={6}>
            <Card className='service_card' sx={{pb : '0px !important'}}>
              <CardContent sx={{p : 0}}>
                <Typography variant="h5" component="h3" className='card_tit' sx={{color : '#202020'}}>
                  PDF SDK
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: '1.38',
                    letterSpacing: 'normal',
                    color: '#202020',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '6',
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  Integrate with Kdan PDF SDK library to enable PDF viewing, editing, signing, annotating, and more in your own applications for mobile, desktop, and web.
                </Typography>
              </CardContent>
              <CardActions sx={{p : 0, mt : 3}}>
                <Button 
                  variant="contained"
                  href="https://www.kdanmobile.com/en/pdf-sdk"
                  target="_blank"
                  endIcon={<ArrowForwardIcon/>} 
                  sx={{
                    backgroundColor : 'rgba(0, 0, 0, 0.7)', 
                    borderRadius : '20px', 
                    height : '40px', 
                    textTransform : 'none',
                    fontSize : '16px'
                    }}>Go to Solution
                </Button>
                <IconButton variant="contained" className="iconButton" href="mailto:business@kdanmobile.com">
                  <MailOutlineIcon/>
                </IconButton>
              </CardActions>

              <CardMedia
                component="img" className='card_img'
                sx={{
                backgroundImage: 'url(' + PDFSDK + ')',
                }}
                // image={cards.img}
                // alt={cards.imgAlt}
              />
            </Card>
          </Grid>
          {cards.map(cards => (
            <Cards cards={cards} key={cards.id} />
          ))}
        </Grid>
    </Container>
  )
}

export default Kdan