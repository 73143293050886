import React from 'react'
import './TopBar.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import logo from 'assets/images/logo.png'

import ToggleButton from '@mui/material/ToggleButton';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';

import Grow from '@mui/material/Grow';

function MenuIcon() {
  return (
    <SvgIcon sx={{width : '32px', height: '32px'}} viewBox="0 0 32 32" className='menuIcon'>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M32 10H0v2h32v-2zm0 10H0v2h32v-2z" fill="#fff" viewBox="-10000"/>
    </SvgIcon>
  );  
}

function MenuIconX() {
  return (
    <SvgIcon sx={{ width: '32px', height: '32px' }} viewBox="0 0 32 32" className='menuIconX'>
      <path d="M14.607 16.02 4 26.628l1.414 1.415 10.607-10.607 10.606 10.607 1.415-1.415-10.607-10.606L28.042 5.414 26.627 4 16.021 14.607 5.414 4 4 5.414l10.607 10.607z" fill="#fff" />
    </SvgIcon>
  );
}


function TopBar() {
  const [selected, setSelected] = React.useState(false);
  const handleClose = (e) => {
    setSelected(null);
    const element  = e.target.name;
    const getElementY = (element) => {
      return window.pageYOffset + element.getBoundingClientRect().top
    }
    window.scrollTo({
      left : 0, 
      top : getElementY(document.getElementById(element)) - 140, behavior: 'smooth'}) 
  };
  const menu = (
    <Box>
      <MenuList>
        <MenuItem className='menuL' component="a" name="alliance" onClick={handleClose}>Alliance</MenuItem>
        <MenuItem component="a" name="sdk" onClick={handleClose}>SDK</MenuItem>
        <MenuItem component="a" name="service" onClick={handleClose}>Service</MenuItem>
        <MenuItem component="a" name="kdan" onClick={handleClose}>Kdan Mobile</MenuItem>
        <MenuItem component="a" name="swingvy" onClick={handleClose}>Swingvy</MenuItem>
        <MenuItem className='menuL' component="a" name="acquisition" onClick={handleClose}>Acquisition</MenuItem>
      </MenuList>
    </Box>
  );
  return (
    <Box className="box" sx={{ flexGrow: 1, width: 1, position: 'fixed', zIndex: '99' }}>
      <AppBar className="AppBar" position="static" sx={{ alignItems: 'center', bgcolor: "#000",pl : 3, pr : 3, }}>
        <Toolbar className='Toolbar' sx={{width : "100%", maxWidth : "768px", p:'0!important',height : '80px' }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, width : "91px"}}>
            <a href='/'><img src={logo} alt='logo' width={91}/></a>
          </Typography>
          <ToggleButton
            value="check"
            selected={selected}
            onChange={() => {
              setSelected(!selected);
            }} 
            sx={{p : 0}}
          >
            <MenuIcon sx={{ width: '32px', height: '32px' }} />
            <MenuIconX sx={{ width: '32px', height: '32px' }}/>
          </ToggleButton>
          <Grow 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              width: '100%', 
              top: 0,
              zIndex : 10,
            }} 
            position='absolute' 
            backgroundColor='rgba(0,0,0,.9)' 
            in={selected}
          >
            {menu}
          </Grow>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default TopBar