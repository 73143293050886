import React from 'react'
import './Service.css';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import HancomDocs from 'assets/images/HancomDocs.png';
import HancomTown from 'assets/images/HancomTown.png';
import HancomTaja from 'assets/images/HancomTaja.png';
import HancomSign from 'assets/images/HancomSign.png';


function DocsIcon() {
  return (
    <SvgIcon sx={{width : '120px', height: '120px', mt : -2, ml : -2}} viewBox="0 0 120 120">
      <g filter="url(#nsbfmhiwna)">
        <path d="M12 31.433C12 20.701 20.7 12 31.433 12h49.134C91.299 12 100 20.7 100 31.433v49.134C100 91.299 91.3 100 80.567 100H31.433C20.701 100 12 91.3 12 80.567V31.433z" fill="#0A46FF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M29.494 62.182H26.32V47.918h3.173c4.166 0 6.653 2.673 6.653 7.15 0 4.455-2.486 7.115-6.652 7.115l-.001-.001zm.181-18.134h-7.774v22.04h7.774c3.278 0 6.028-1.033 7.956-2.99 1.921-1.95 2.936-4.727 2.936-8.03 0-3.302-1.015-6.08-2.936-8.029-1.928-1.956-4.678-2.991-7.956-2.991zM62.84 58.109c0 4.834 3.608 8.34 8.45 8.34 1.651 0 3.374-.623 4.406-1.581v-3.73c-.965.806-2.27 1.604-3.862 1.604-2.3 0-4.72-1.578-4.72-4.633 0-1.393.54-2.58 1.454-3.434.844-.786 2.028-1.219 3.266-1.219 1.423 0 2.734.381 3.862 1.199v-3.522c-1.13-.865-2.813-1.401-4.405-1.401-4.844 0-8.451 3.523-8.451 8.377zm23.75-1.395-1.813-.544c-1.092-.352-1.806-.654-1.806-1.608 0-.906.868-1.468 2.268-1.468 1.613 0 3.298.502 4.874 1.56v-3.517c-1.477-.946-3.142-1.406-5.092-1.406-3.74 0-6.252 2-6.252 4.975 0 2.475 1.399 3.955 4.536 4.798l1.56.435c1.086.35 1.916.694 1.916 1.68 0 .934-.906 1.467-2.485 1.467-1.656 0-3.757-.736-5.49-1.947v3.729c1.306.978 3.46 1.582 5.635 1.582 4.058 0 6.578-1.878 6.578-4.903 0-2.513-1.325-3.959-4.43-4.834l.002.001zM51.704 62.78c-2.425 0-4.428-2.15-4.428-4.67 0-2.586 1.817-4.707 4.428-4.707 2.537 0 4.427 2.121 4.427 4.707 0 2.52-1.858 4.67-4.427 4.67zm0-13.047c-5.297 0-8.609 3.813-8.609 8.377 0 4.543 3.312 8.34 8.609 8.34 5.296 0 8.608-3.797 8.608-8.34 0-4.564-3.312-8.377-8.608-8.377zM80.866 28.355c0 .476-.025.848-.04 1.01.152-.037.515-.1 1.19-.1h2.639v1.64h-.835c-.782 0-1.632-.366-1.86-.503v.098c1.038.326 1.73 1.44 1.73 2.788 0 1.951-1.532 3.367-3.644 3.367s-3.647-1.416-3.647-3.367c0-1.349.694-2.462 1.73-2.788v-.098c-.227.136-1.075.503-1.858.503h-.835v-1.64h2.638c.676 0 1.038.063 1.19.1a12.49 12.49 0 0 1-.04-1.01v-.896h1.642v.896zm-.821 6.733c1.18 0 2.004-.787 2.004-1.912 0-1.156-.806-1.932-2.003-1.932-1.198 0-2.004.777-2.004 1.932 0 1.125.824 1.912 2.003 1.912z" fill="#fff"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M70.914 32.115c0-5.035 4.087-9.115 9.128-9.115s9.13 4.08 9.13 9.115c0 5.033-4.088 9.114-9.129 9.114-5.042 0-9.129-4.08-9.129-9.114zm1.743 0c0 4.072 3.308 7.374 7.385 7.374 4.08 0 7.386-3.302 7.386-7.374 0-4.073-3.306-7.375-7.385-7.375-4.08 0-7.386 3.302-7.386 7.375z" fill="#fff"/>
    </g>
    <defs>
        <filter id="nsbfmhiwna" x="0" y="0" width="120" height="120" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="4" dy="4"/>
            <feGaussianBlur stdDeviation="8"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1151_16047"/>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_1151_16047" result="shape"/>
        </filter>
    </defs>
    </SvgIcon>
  );
}
function TownIcon() {
  return (
    <SvgIcon sx={{width : '120px', height: '120px', mt : -2, ml : -2}} viewBox="0 0 120 120">
      <g filter="url(#9ly2m226ba)">
        <g clip-path="url(#g2kywo1a8b)">
            <path d="M80.702 100H31.298C20.638 100 12 91.362 12 80.702V31.298C12 20.638 20.638 12 31.298 12h49.404C91.362 12 100 20.638 100 31.298v49.404C100 91.362 91.362 100 80.702 100z" fill="#6646CC"/>
            <path d="M54.41 57.058a9.794 9.794 0 0 0-9.796 9.795 9.794 9.794 0 0 0 9.796 9.796 9.794 9.794 0 0 0 9.796-9.796 9.794 9.794 0 0 0-9.796-9.795M49.655 44.382h-26.3v9.047h7.966v14.42h10.36V53.43h7.974v-9.047zM67.247 73.808h8.383V66.9c0-1.29 1.05-2.34 2.34-2.34 1.288 0 2.338 1.05 2.338 2.34v6.908h8.383V56.193H67.24v17.615h.008zM81.999 37.89c-4.354 0-7.89-3.543-7.89-7.889s3.544-7.896 7.89-7.896 7.889 3.543 7.889 7.896c0 4.354-3.543 7.89-7.89 7.89zm0-17.646c-5.38 0-9.757 4.377-9.757 9.757 0 1.969.594 3.806 1.597 5.342h-.085l-2.616 8.808-1.969-6.199h-6.16l-1.968 6.199-2.617-8.808h-8.738l5.388 17.863h22.023l4.068-13.486c.27.023.548.039.826.039 5.38 0 9.757-4.377 9.757-9.758 0-5.38-4.377-9.757-9.757-9.757" fill="#fff"/>
            <path d="M84.145 31.013c0 1.204-.88 2.045-2.139 2.045-1.258 0-2.138-.841-2.138-2.045 0-1.205.857-2.07 2.138-2.07 1.282 0 2.139.834 2.139 2.07zm-1.305-4.076c.016-.17.039-.571.039-1.08v-.958h-1.753v.957c0 .51.031.911.039 1.08-.162-.038-.548-.107-1.274-.107h-2.817v1.752h.74c.927 0 1.522-.123 2.147-.617l.162.185c-1.042.409-2.023 1.544-2.023 2.987 0 2.084 1.637 3.605 3.899 3.605 2.261 0 3.898-1.513 3.898-3.605 0-1.443-.98-2.57-2.023-2.987l.163-.186c.625.502 1.22.618 2.145.618h.742v-1.752h-2.818c-.726 0-1.112.07-1.274.108" fill="#fff"/>
        </g>
    </g>
    <defs>
        <clipPath id="g2kywo1a8b">
            <rect x="12" y="12" width="88" height="88" rx="20" fill="#fff"/>
        </clipPath>
        <filter id="9ly2m226ba" x="0" y="0" width="120" height="120" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="4" dy="4"/>
            <feGaussianBlur stdDeviation="8"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1151_16046"/>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_1151_16046" result="shape"/>
        </filter>
    </defs>
    </SvgIcon>
  );
}
function TajaIcon() {
  return (
    <SvgIcon sx={{width : '120px', height: '120px', mt : -2, ml : -2}} viewBox="0 0 120 120">
      <g filter="url(#uvm370crua)">
        <g clip-path="url(#guxagprjlb)">
            <path d="M80.702 100H31.298C20.638 100 12 91.362 12 80.702V31.298C12 20.638 20.638 12 31.298 12h49.404C91.362 12 100 20.638 100 31.298v49.404C100 91.362 91.362 100 80.702 100z" fill="#FFBF4D"/>
            <path d="M83.527 19.912c-4.716 0-8.553 3.837-8.553 8.553 0 4.717 3.837 8.553 8.553 8.553 4.716 0 8.553-3.836 8.553-8.553 0-4.716-3.837-8.553-8.553-8.553m0 1.451c3.93 0 7.11 3.18 7.11 7.11s-3.18 7.11-7.11 7.11-7.11-3.18-7.11-7.11 3.18-7.11 7.11-7.11z" fill="#fff"/>
            <path d="M83.535 31.314c-1.174 0-1.868-.85-1.868-1.9 0-1.05.694-1.898 1.868-1.898 1.173 0 1.868.849 1.868 1.899s-.695 1.899-1.868 1.899zm1.698-5.18c-.324 0-.687.054-.911.085.039-.232.077-.533.077-.81v-1.305h-1.744v1.304c0 .278.038.58.077.811a5.722 5.722 0 0 0-.911-.085h-2.918v1.567H80.1c.154 0 .332-.023.54-.093.193-.061.51-.223.725-.362l.047.07c-.703.47-1.181 1.196-1.243 2.099-.123 1.76 1.382 3.242 3.35 3.242 1.969 0 3.474-1.49 3.358-3.242-.062-.911-.548-1.637-1.25-2.108l.046-.07c.224.14.532.302.725.364.201.07.386.092.54.092h1.197v-1.567h-2.918l.016.008zM72.828 41.194a5.552 5.552 0 0 0-7.07 3.42 5.56 5.56 0 0 0 3.419 7.079 5.552 5.552 0 0 0 7.07-3.42 5.56 5.56 0 0 0-3.419-7.079" fill="#fff"/>
            <path d="M34.054 34.749V45.03s5.774-2.324 13.246-4.16v22.933h10.39v-25.18c3.83-.656 7.434-1.197 11.417-1.606v-7.943c-19.7 1.652-35.06 5.666-35.06 5.666" fill="#fff"/>
            <path d="M66.923 56.015c.571 7.897-1.86 14.15-6.986 16.141-4.77 1.845-10.97.44-17.122-4.044l-6.067 7.233c8.059 7.333 19.229 8.112 26.57 5.65 5.156-1.737 14.79-7.812 13.393-25.628l-9.773.656-.015-.008z" fill="#fff"/>
        </g>
    </g>
    <defs>
        <clipPath id="guxagprjlb">
            <rect x="12" y="12" width="88" height="88" rx="20" fill="#fff"/>
        </clipPath>
        <filter id="uvm370crua" x="0" y="0" width="120" height="120" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="4" dy="4"/>
            <feGaussianBlur stdDeviation="8"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1151_16045"/>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_1151_16045" result="shape"/>
        </filter>
    </defs>
    </SvgIcon>
  );
}
function SignIcon() {
  return (
    <SvgIcon sx={{width : '120px', height: '120px', mt : -2, ml : -2}} viewBox="0 0 120 120">
      <g filter="url(#j4rvp6whva)">
        <g clip-path="url(#t5aq41ackb)">
            <path d="M80.702 100H31.298C20.638 100 12 91.362 12 80.702V31.298C12 20.638 20.638 12 31.298 12h49.404C91.362 12 100 20.638 100 31.298v49.404C100 91.362 91.362 100 80.702 100z" fill="#161973"/>
            <path d="m52.01 41.704-4 1.56-.663 3.728 4.021-1.575.641-3.713zM62.538 58.408l-3.28 3.088c-.811-.672-1.174-1.235-1.56-2.092-.378-.857-.571-1.837-.571-2.949 0-1.505.255-2.917.934-4.237.672-1.297 1.667-2.2 3.458-2.988l2.517.842-1.498 8.352v-.016zm-1.837-9.703a15.992 15.992 0 0 0-3.057 1.753c-.926.687-1.721 1.443-2.377 2.292a10.133 10.133 0 0 0-1.529 2.725c-.363.973-.54 2-.54 3.088 0 1.459.355 2.686 1.073 3.682s1.667 1.783 2.848 2.362l5.21-4.894-1.427 7.959h-9.588l-.687 3.852h7.241l7.341-6.902 2.849-16.017-4.308-1.281-3.057 1.389.008-.008zM36.077 42.051c-2.293 0-3.783.44-5.087 1.891-.75.834-1.467 2.139-1.467 3.76 0 1.775.695 3.095 1.706 4.137.363.379.764.703 1.18 1.042l3.104 2.548c2.17 1.783 3.018 3.226 3.142 4.515.115 1.135-.178 2.579-1.135 2.888l-7.372-6.037-5.187 4.879.617.532 2.177-2.045 4.516 3.867h2.856c2.601 0 6.546-1.173 6.546-6.307 0-1.242-.232-2.254-.703-3.033-.47-.78-1.111-1.49-1.922-2.146l-3.55-2.887a91.92 91.92 0 0 1-1.32-1.073c-1.266-1.027-2.139-2.331-1.753-3.898.255-1.035.965-1.714 1.791-1.853l5.774 4.709 2.625-2.47-3.721-3.019h-2.818zM50.96 60.03l-2.587 2.431 2.663-15.153-7.225 2.825.294.75 2.74-1.074-2.416 13.54 2.84 1.258 4.2-3.937-.51-.64zM82.971 62.392l1.714-9.557a4.203 4.203 0 0 0-2.933-4.77l-5.55 2.169.493-2.91-7.217 2.817.293.749 2.733-1.066-2.486 14.212h3.752l2.285-12.876 2.177-.85c1.474-.578 3.002.68 2.732 2.24L79.05 63.403l2.74 1.22 4.269-4.015-.502-.64-2.586 2.431v-.007zM81.057 38.361c-4.091 0-7.418-3.327-7.418-7.418s3.327-7.418 7.418-7.418 7.418 3.327 7.418 7.418-3.327 7.418-7.418 7.418zm0-16.58c-5.056 0-9.17 4.114-9.17 9.17s4.114 9.163 9.17 9.163 9.17-4.115 9.17-9.163c0-5.049-4.114-9.17-9.17-9.17" fill="#fff"/>
            <path d="M83.064 32.008c0 1.135-.826 1.922-2.007 1.922s-2.015-.787-2.015-1.922c0-1.134.81-1.945 2.015-1.945 1.204 0 2.007.78 2.007 1.945zm-1.228-3.828c.016-.163.039-.54.039-1.02v-.902h-1.652v.903c0 .478.023.856.039 1.019-.155-.039-.517-.1-1.197-.1h-2.647v1.651h.84c.788 0 1.637-.37 1.869-.51v.101c-1.042.324-1.737 1.451-1.737 2.802 0 1.96 1.536 3.389 3.66 3.389 2.122 0 3.658-1.42 3.658-3.389 0-1.358-.695-2.478-1.737-2.802v-.1c.224.139 1.08.51 1.868.51h.842v-1.653h-2.648c-.68 0-1.042.062-1.197.1" fill="#fff"/>
        </g>
    </g>
    <defs>
        <clipPath id="t5aq41ackb">
            <rect x="12" y="12" width="88" height="88" rx="20" fill="#fff"/>
        </clipPath>
        <filter id="j4rvp6whva" x="0" y="0" width="120" height="120" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="4" dy="4"/>
            <feGaussianBlur stdDeviation="8"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1151_16044"/>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_1151_16044" result="shape"/>
        </filter>
    </defs>
    </SvgIcon>
  );
}
function Cards({ cards }) {
  return (
    <Grid item xs={12} sm={6} >
      <Card className='service_card' sx={{ backgroundColor : cards.backgroundColor}}>
        <CardContent sx={{p : 0}}>
          {cards.logo}
          <Typography variant="h5" component="h3" className='card_tit' sx={{color : cards.color, mt : '10px'}}>
            <img src={cards.img} alt={cards.alt} height='25px'/>
          </Typography>
          <Typography
            sx={{
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: '1.38',
              letterSpacing: 'normal',
              color: cards.color,
              // overflow: 'hidden',
              // textOverflow: 'ellipsis',
              // display: '-webkit-box',
              // WebkitLineClamp: '4',
              // WebkitBoxOrient: 'vertical',
            }}
          >
            {cards.contents}
          </Typography>
        </CardContent>
        <CardActions sx={{p : 0, mt : 'auto'}}>
          <Button 
            variant="contained" 
            endIcon={<ArrowForwardIcon/>} 
            href={cards.url}
            target="_blank"
            sx={{
              backgroundColor : 'rgba(0, 0, 0, 0.7)', 
              borderRadius : '20px', 
              height : '40px', 
              textTransform : 'none',
              fontSize : '16px'
              }}>Go to Service
          </Button>
          <IconButton variant="contained" className="iconButton"  href={cards.email}>
            <MailOutlineIcon/>
          </IconButton>
        </CardActions>
      </Card>
    </Grid>
  );
}

const cards = [
  {
    id: 1,
    logo : <DocsIcon/>,
    img: HancomDocs,
    alt: 'Hancom Docs',
    contents: 'Hancom Docs is a subscription-based service that allows you to create and save the latest version of documents anytime, anywhere, even on any platform.',
    backgroundColor : '#0a46ff',
    color : '#fff',
    url: 'https://www.hancomdocs.com/',
    email : 'mailto:help.hancomdocs@hancom.com '
  },
  {
    id: 2,
    logo : <TownIcon/>,
    img: HancomTown,
    alt: 'Hancom Town',
    contents: 'Hancom Town Coworking connects your day to the virtual.It provides an efficient human and material resource management system for managers and various collaboration tools including Hancom Docs for members.',
    backgroundColor : '#6646cc',
    color : '#fff',
    url: 'https://coworking.hancom.town',
    email: 'mailto:help.coworking@hancom.com' 
  },
  {
    id: 3,
    logo : <TajaIcon/>,
    img: HancomTaja,
    alt: 'Hancom Taja',
    contents: 'Hancom Taja is a service that allows you to practice typing in various ways. Text to Speech (TTS) gives you an opportunity to practice typing and learn a foreign language.',
    backgroundColor : '#ffbf4d',
    color: '#202020',
    url: 'https://hancomtaja.com',
    email: 'mailto:typing@hancom.com'
  },
  {
    id: 4,
    logo : <SignIcon/>,
    img: HancomSign,
    alt: 'Hancom Sign',
    contents: 'Hancom Sign is an electronic signature service that allows you to sign, track and manage the signature process.',
    backgroundColor : '#161973',
    color: '#fff',
    url: 'https://www.hancomsign.com/',
    email: 'mailto:hancomsign@hancom.com '
  }
];

function Service() {
  return (
    <Container disableGutters sx={{maxWidth:'768px', width : 1}}>
      <Typography component="h2" id="service" className='sdk_tit'># Hancom Service</Typography>
        <Grid container spacing={2} sx={{ mt: 0 }}>
          {cards.map(cards => (
            <Cards cards={cards} key={cards.id} />
          ))}
        </Grid>
    </Container>
  )
}

export default Service