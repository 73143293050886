import React from 'react'
// import './Swingvy.css';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import IconButton from '@mui/material/IconButton';

import SwingvyIcon from 'assets/images/SwingvyIcon.png';

function Cards({ cards }) {
  return (
    <Grid item xs={12} sm={6} >
      <Card className='service_card' sx={{ backgroundColor : cards.backgroundColor}}>
        <CardContent sx={{p : 0}}>
          <img src={cards.img} alt={cards.alt} width='88px'/>
          <Typography variant="h5" component="h3" className='card_tit' sx={{color : '#141414', mt : 4}}>
            {cards.title}
          </Typography>
          <Typography
            sx={{
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: '1.38',
              letterSpacing: 'normal',
              color: '#141414',
              whiteSpace : 'pre-wrap'
            }}
          >
            {cards.contents}
          </Typography>
        </CardContent>
        <CardActions sx={{p : 0, mt : 'auto'}}>
          <Button 
            variant="contained" 
            href={cards.url}
            target="_blank"
            endIcon={<ArrowForwardIcon/>} 
            sx={{
              backgroundColor : 'rgba(0, 0, 0, 0.7)', 
              borderRadius : '20px', 
              height : '40px', 
              textTransform : 'none'
              }}>Go to Product
          </Button>
          <IconButton variant="contained" className="iconButton" href={cards.email}>
            <MailOutlineIcon/>
          </IconButton>
        </CardActions>
      </Card>
    </Grid>
  );
}

const cards = [
  {
    id: 1,
    img : SwingvyIcon,
    alt : 'SwingvyIcon',
    title: 'Swingvy',
    contents: 'Swingvy is a cloud-based human resource platform for small and mid-sized businesses. Supporting 10,000 growing companies and SMEs to simplify HR operations. Swingvy HR software makes it easy to onboard, pay, reimburse, track, and support your team.',
    color : '#202020',
    url: 'https://swingvy.com',
    email: 'mailto:partnership@swingvy.com'
  },
];

function Swingvy() {
  return (
    <Container disableGutters sx={{maxWidth:'768px', width : 1}}>
      <Typography component="h2" id="swingvy" className='sdk_tit'># Swingvy</Typography>
        <Grid container spacing={2} sx={{ mt: 0 }}>
          {cards.map(cards => (
            <Cards cards={cards} key={cards.id} />
          ))}
        </Grid>
    </Container>
  )
}

export default Swingvy