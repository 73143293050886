import * as React from 'react';
import './App.css';
import Header from './components/Header/Header';
import TopBar from './components/TopBar/TopBar';
import Sdk from './components/Sdk/Sdk'
import Service from './components/Service/Service'
import Kdan from './components/Kdan/Kdan'
import Swingvy from './components/Swingvy/Swingvy'
import Acquisition from './components/Acquisition/Acquisition'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';


const theme = createTheme({
  breakpoints: {  
    values: {
      xs: 0,
      sm: 768
    },
  }  
})

function Copyright() {
  return (
    <Typography align="center" sx={{color : '#fff', opacity : .6, fontWeight : 500, letterSpacing : 'normal', py : '49px', mt : '200px', height : 120,}}>
      {'© 2023 HANCOM ALL RIGHTS RESERVED.'}
    </Typography>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component='main' className='main'>
          <TopBar></TopBar>  
          <Box sx={{mr : 3, ml : 3}}>
            <Header></Header>
            <Sdk></Sdk>
            <Service></Service>
            <Kdan></Kdan>
            <Swingvy></Swingvy>
            <Acquisition></Acquisition>
          </Box>
          <Copyright/>
      </Container>
    </ThemeProvider>
  )
}

export default App;
